export default function() {
	return {
		switchLang() {
			const url = window.location.href;
			// find language in url
			const lang = url.match(/\/(en|fr)\//);
			const currentLang = lang ? lang[1] : 'en';
			const newLang = currentLang === 'en' ? 'fr' : 'en';
			// set language on html 
			document.documentElement.lang = newLang;
			window.app.language= newLang;
		},
	};
}