import { list } from "postcss";

export default function() {
	return {

		click() {
			// scroll to top
			// console.log('scroll to top');
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		windowHasScroll(listContainer) {
				return listContainer.clientHeight > window.innerHeight;
		}
	};
}