import Swiper from 'swiper';
import { Pagination, Mousewheel, Autoplay } from 'swiper/modules';
import SlideProgress from './SlideProgress';


export default function() {
	return {
		swiper: null,
		currentIndex: 1,
		id: '',
		setup(id) {
			this.id = id;
			this._init();
		},
		_init() {
			const slideshow = document.getElementById('home-slideshow-'+this.id);

			if (!slideshow) {
				return;
			}
		
			let progress = null; 

			const descriptions = document.querySelectorAll('.home-slideshow-description-'+this.id);
			const button = document.querySelector('#home-slideshow-button-'+this.id);
			const h1 = document.querySelector('#home-slideshow-content--button-'+this.id+' h1');
		
			Swiper.use([Pagination, Mousewheel, Autoplay]);
			this.swiper = new Swiper(slideshow, {
				loop: true,
				direction: "vertical",
				speed: 1000,
				slidesPerView: 1,
				spaceBetween: 30,
				mousewheel: true,
				autoplay: {
					delay: 5000,
				},
				on: {
					init: function() {
						progress = new SlideProgress(slideshow, this);
					},
					afterInit: function() {
						progress.updateProgress();
					},
				}
			});
		
			this.swiper.on('slideChange', () => {
				progress.updateProgress();
				this.updateBtn(button, descriptions, h1);
				this.currentIndex = this.swiper.realIndex + 1;
			});

			const url = descriptions[0].dataset.itemUrl;
			button.setAttribute('href', url);
		
			this.updateBtn(button, descriptions, h1);
			window.lazyloadInstance.update();
		},
		updateBtn(button, descriptions, h1) {
				const url = descriptions[this.swiper.realIndex].dataset.itemUrl;
				const text = descriptions[this.swiper.realIndex].dataset.itemBtntext;
				const btnStyle = descriptions[this.swiper.realIndex].dataset.itemBtnstyle;
				const taglineColor = descriptions[this.swiper.realIndex].dataset.itemTaglineColor;
				if(text){
					const span = document.querySelector('#home-slideshow-button-'+this.id+' > span > span');
					if(span){
						span.innerHTML = text;
					}
					
				}
				if(btnStyle){
					button.className = btnStyle + ' group transition-all duration-300';
				}
				if(taglineColor){
					h1.classList.remove('text-white', 'text-black');
					h1.classList.add(taglineColor);
				}
				button.setAttribute('href', url);
		},
		pause(value) {
			if (value) {
				this.swiper.autoplay.stop();
			} else {
				this.swiper.autoplay.start();
			}
		},
		previous() {
			this.swiper.slidePrev();
		},
		next() {
			this.swiper.slideNext();
		}
	};
}