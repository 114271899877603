export default function () {
	return {
		currentSort: null,
		activeBnt: null,
		sortDirection: 'asc',
		callBack: null,
		setup(callBack){
			this.callBack = callBack;
			this._init();
		},
		_init() {
			// console.log('init');
			this.items = this.$refs.list.querySelectorAll('li');
			this.callBack(this.items);
		},
		resetActive() {
			if(this.activeBnt) {
				this.activeBnt.querySelector('.sort-arrow').classList.add('hidden');
			}

		},
 		sort(event,id) {
			// console.log('sort', id, event.currentTarget);
			this.resetActive();
			const btn = event.currentTarget;
			this.activeBnt = btn;
			const icon = btn.querySelector('.sort-arrow');
			icon.classList.remove('hidden');


			if(this.currentSort == id && this.sortDirection == 'asc') {
				this.sortDirection = 'desc';
			} else {
				this.currentSort = id;
				this.sortDirection = 'asc';
			}

			if(this.sortDirection == 'asc') {
				icon.classList.add('rotate-180');
			} else {
				icon.classList.remove('rotate-180');
			}

			// sort items by title 
			const sorted = [...this.items].sort((a, b) => {
				const itemA = a.querySelector('.sortable-item[data-sort-id='+id+']');
				const itemB = b.querySelector('.sortable-item[data-sort-id='+id+']');

				const aTitle = itemA ? itemA.dataset.sortValue : null;
				const bTitle = itemB ? itemB.dataset.sortValue : null;
				if (aTitle < bTitle) {
					return this.sortDirection == 'asc' ? -1 : 1;
				}
				if (aTitle > bTitle) {
					return this.sortDirection == 'asc' ? 1 : -1;
				}
				return 0;
			}).map(item => item.outerHTML);

			// replace items with sorted items
			this.$refs.list.innerHTML = sorted.join('');
		
			document.scrollingElement.scrollTop = 0;
			

		
		}
	};
}