import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules';
import SlideProgress from './SlideProgress';

function scrollDown(el) {

	el.classList.add('is-scrolling-down');
	el.classList.remove('is-scrolling-up');
}

function scrollUp(el) {
	el.classList.remove('is-scrolling-down');
	el.classList.add('is-scrolling-up');
}

export default function() {
	return {
		currentId: null,
		teamIsActive: false,
		id: '',

		setup(id) {
			this.id = id;
			this._init();
		},
		_init() {
			// console.log('init expertise slideshow');
			this.onResize();
			this.isDown = true;

			window.addEventListener('resize', () => {
				this.onResize();
			});

			this.initDesktop();
			this.initMobile();
			
		},
		showTeam(id) {
			this.currentId = id;
			this.teamIsActive = true;
		},
		hideTeam() {
			this.currentId = null;
			this.teamIsActive = false;
		},
		initDesktop() {
			const slideshow = document.getElementById('expertise-slideshow-'+this.id);

			if (!slideshow) {
				return;
			}

			this.slideshowContent = document.getElementById('expertise-slideshow-content-'+this.id);
			this.page = document.getElementById('expertise-page-'+this.id);
		
			let progress = null;
			let current = 0;
		
			Swiper.use([Pagination, Mousewheel]);
			this.swiper = new Swiper(slideshow, {
				loop: true,
				direction: "vertical",
				speed: 1000,
				slidesPerView: 1,
				spaceBetween: 30,
				mousewheel: true,
				allowTouchMove: false,
				on: {
					init: (s) => {
						progress = new SlideProgress(this.page, s);
					},
					afterInit: () => {
						progress.updateProgress();
					},
				}
			});
			const count = this.slideshowContent.dataset.swiperCount;

			this.swiperContent = new Swiper(this.slideshowContent, {
				loop: true,
				direction: "vertical",
				speed: 1000,
				slidesPerView: 1,
				spaceBetween: 30,
				initialSlide: count,
			});
		
			this.swiper.on('slideChange', () => {
				if(this.swiperContent.slides[this.swiperContent.realIndex]){
					progress.updateProgress();
					if(this.isDown) {
						this.swiperContent.slidePrev();
					} else {
						this.swiperContent.slideNext();
					}

					this.mobileSwiper.slideTo(this.swiper.realIndex);
					current = this.swiper.realIndex;
					this.hideTeam();				
				}
			});
			
			let deltaY = 0;
			let prevScrollStep = 0;
			let scrollStep = 0;
		
			this.page.addEventListener('wheel', (e) => {
				if(this.isMobile || this.teamIsActive) return;
				
				if(e.deltaY > 0) {
					scrollDown(this.slideshowContent);
					this.isDown = true;
				} else {
					scrollUp(this.slideshowContent);
					this.isDown = false;
				}

				deltaY += e.deltaY;
				scrollStep = Math.floor(deltaY/500);
				if (e.deltaY > 0 && scrollStep > prevScrollStep) {
					this.swiper.slideNext();
				} else if(e.deltaY < 0 && scrollStep < prevScrollStep) {
					this.swiper.slidePrev();
				}

				if (this.swiper.realIndex == 0) {
					scrollDown(this.slideshowContent);
				} else if(this.swiper.realIndex == this.swiper.slides.length - 1) {
					scrollUp(this.slideshowContent);
				}
				prevScrollStep = scrollStep;
			});

			let touchStartPosX = 0;
			this.page.addEventListener('touchstart', (e) => {
				if(this.isMobile || this.teamIsActive) return;

				touchStartPosX = Math.round(e.changedTouches[0].screenY);
			});

			this.page.addEventListener('touchend', (e) => {
				if(this.isMobile || this.teamIsActive) return;

				const currentPageX = Math.round(e.changedTouches[0].screenY);
				if (touchStartPosX === currentPageX) return;
			
				if (touchStartPosX - currentPageX > 0) {
					scrollDown(this.slideshowContent);
					this.isDown = true;
					this.swiper.slideNext();
				} else {
					scrollUp(this.slideshowContent);
					this.isDown = false;
					this.swiper.slidePrev();
				}
				touchStartPosX = currentPageX;

				// if (this.swiper.realIndex == 0) {
				// 	scrollDown(this.slideshowContent);
				// } else if(this.swiper.realIndex == this.swiper.slides.length - 1) {
				// 	scrollUp(this.slideshowContent);
				// }
			  });
		
			window.lazyloadInstance.update();
				
			window.addEventListener('resize', () => {
				if(progress.total === 0) {
					progress.init();
				}
			});
		},
		previous() {
			scrollUp(this.slideshowContent);
			this.isDown = false;
			this.swiper.slidePrev();
		},
		next() {
			scrollDown(this.slideshowContent);
			this.isDown = true;
			this.swiper.slideNext();
		},
		initMobile() {
			const mobileSlideshow = document.getElementById('mobile-expertise-slideshow-'+this.id);
			
			
			
			if (!mobileSlideshow) {
				return;
			}
			this.mobileContainer = document.getElementById('expertise-mobile-'+this.id);
			let progress = null;

			const firstSlide = mobileSlideshow.querySelector('.swiper-slide');
			this.currentColor = firstSlide.dataset.bgColor;

			this.mobileSwiper = new Swiper(mobileSlideshow, {
				loop: true,
				direction: "vertical",
				speed: 500,
				mousewheel: true,
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 1,
				},
				on: {
					init: (s) => {
						progress = new SlideProgress(this.mobileContainer, s);
						
					},
					afterInit: () => {
						progress.updateProgress();
						this.replacePagination();
					},
				}
			});

			this.mobileSwiper.on('slideChange', () => {
				this.hideTeam();
				progress.updateProgress();
			});
			window.addEventListener('resize', () => {
				if(progress.total === 0) {
					progress.init();
				}
			});

		},
		onResize() {
			if(window.innerWidth < 1024 ) {
				this.isMobile = true;
				this.replacePagination();
			} else {
				this.isMobile = false;
			}
			this.hideTeam();
			
		},
		replacePagination() {
			if(!this.mobileContainer) {
				return;
			}

			const pagination = this.mobileContainer.querySelector('.swiper-pagination');
			const right = this.mobileContainer.querySelector('.expertise-mobile__right');
			if(right.clientHeight > pagination.clientHeight) {
				const delta = right.clientHeight - pagination.clientHeight;
				if(delta/2 > 20) {
					pagination.style.marginBottom = delta/2 + 'px';
				} else {
					pagination.style.marginBottom = '20px';
				}
			}

		},
	};
}