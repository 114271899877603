import barba from '@barba/core';
import anime from 'animejs/lib/anime.es.js';

const transitionWrapper = document.querySelector('.transition-wrapper');
const panelLeftLeave = transitionWrapper.querySelector('.transition-panel--left-leave');
const panelRightLeave = transitionWrapper.querySelector('.transition-panel--right-leave');
const panelLeftEnter = transitionWrapper.querySelector('.transition-panel--left-enter');
const panelRightEnter = transitionWrapper.querySelector('.transition-panel--right-enter');

function hideTopMenu(data) {
	const topMenu = data.current.container.querySelector('.top-menu.fixed');
	const nextTopMenu = data.next.container.querySelector('.top-menu.fixed');
	if (topMenu && !nextTopMenu) {
		const topMenuFixed = document.querySelector('.top-menu.fixed');
		if (topMenuFixed) {
			topMenuFixed.classList.remove('z-[60]');
			topMenuFixed.classList.add('z-0');
		}
	}
}

export default function () {
	barba.init({
		debug: true,
		timeout: 10000,
		transitions: [
			dualTransition(),
			singleRightTransition()
		],
		preventRunning: true
	});
}

export function dualTransition() {
	return {
		name: 'dual',
		namespace: ['dual'],
		sync: false,
		from: {
			namespace: ['single-right', 'dual']
		},
		to: {
			namespace: ['dual', 'single-right']
		},
		beforeLeave(data) {
			document.body.classList.add('is-transitioning');
			hideTopMenu(data);
		},
		leave() {
			window.Alpine.store('menu').menuActive = false;
			document.body.classList.remove('overlay-is-active');
			const tl = anime.timeline({
				duration: 500,
				easing: 'easeOutQuad'
			});

			tl.add({
				targets: panelLeftLeave,
				scaleY: [0, 1]
			});
			tl.add(
				{
					targets: panelRightLeave,
					scaleY: [0, 1]
				},
				'-=500'
			);

			return tl.finished;
		},
		beforeEnter() {
			window.lazyloadInstance.update();

			const overlayBg = document.getElementById('overlay-menu-bg');
			overlayBg.addEventListener('click', function() {
				if (window.Alpine.store('menu').menuActive) {
					document.body.classList.remove('overlay-is-active');
					window.Alpine.store('menu').menuActive = false;
				}
			});
		},
		afterLeave() {
			window.scrollTo(0, 0);
		},
		after() {
			setTimeout(() => {
				document.body.classList.remove('is-transitioning');
			}, 500);
			// console.log('enter:');
			const tl = anime.timeline({
				duration: 500,
				easing: 'easeOutQuad'
			});

			tl.add({
				targets: panelLeftEnter,
				scaleY: [1, 0]
			});
			tl.add(
				{
					targets: panelRightEnter,
					scaleY: [1, 0]
				},
				'-=500'
			);
			panelLeftLeave.style.transform = 'scaleY(0)';
			panelRightLeave.style.transform = 'scaleY(0)';
			return tl.finished;
		}
	};
}

export function singleRightTransition() {
	return {
		name: 'single-right',
		namespace: ['single-right'],
		from: {
			namespace: ['single-right']
		},
		to: {
			namespace: ['single-right']
		},
		sync: false,
		beforeLeave(data) {
			document.body.classList.add('is-transitioning');
			// hideTopMenu(data);
		},
		leave() {
			window.Alpine.store('menu').menuActive = false;
			document.body.classList.remove('overlay-is-active');

			const tl = anime.timeline({
				duration: 500,
				easing: 'easeOutQuad'
			});
			tl.add({
				targets: panelRightLeave,
				scaleY: [0, 1]
			});

			return tl.finished;
		},
		beforeEnter() {
			window.lazyloadInstance.update();
		},
		after() {
			setTimeout(() => {
				document.body.classList.remove('is-transitioning');
			}, 500);
			// console.log('enter:');
			const tl = anime.timeline({
				duration: 500,
				easing: 'easeOutQuad'
			});
			tl.add({
				targets: panelRightEnter,
				scaleY: [1, 0]
			});
			panelRightLeave.style.transform = 'scaleY(0)';
			return tl.finished;
		},
		afterLeave() {
			window.scrollTo(0, 0);
		}
	};
}


