import '/assets/styles/main.pcss';

import LazyLoad from 'vanilla-lazyload';
import Alpine from 'alpinejs';
import PageTransition from './core/PageTransition';

// components
import Splash from './components/Splash';
import HomeSlideshow from './components/HomeSlideshow';
import ExpertiseSlideshow from './components/ExpertiseSlideshow';
import Projects from './components/Projects';
import TopMenu from './components/TopMenu';
import ProjectDetail from './components/ProjectDetail';
import ImageBlocks from './components/ImageBlocks';
import Site from './components/Site';
import News from './components/News';
import Collapsables from './components/Collapsables';
import ScrollableList from './components/ScrollableList';
import FilterableList from './components/FilterableList';
import SortableList from './components/SortableList';
import Careers from './components/Careers';
import BackLink from './components/BackLink';
import ReturnToTop from './components/ReturnToTop';
import PartnerPage from './components/PartnerPage';
import SiteSwitcher from './components/SiteSwitcher';

window.lazyloadInstance = new LazyLoad();
window.Alpine = Alpine;

Alpine.store('menu', {
	menuActive: false,
});



Alpine.data('siteController', () => Site);
Alpine.data('projectController', () => Projects());
Alpine.data('homeSlideshowController', () => HomeSlideshow());
Alpine.data('expertiseController', () => ExpertiseSlideshow());
Alpine.data('topMenuController', () => TopMenu);
Alpine.data('projectDetailController', () => ProjectDetail);
Alpine.data('imageBlocksController', () => ImageBlocks);
Alpine.data('newsController', () => News);
Alpine.data('collapsablesController', () => Collapsables);
Alpine.data('scrollableListController', () => ScrollableList);
Alpine.data('filterableListController', () => FilterableList);
Alpine.data('sortableListController', () => SortableList);
Alpine.data('careersController', () => Careers);
Alpine.data('backLinkController', () => BackLink);
Alpine.data('returnToTopController', () => ReturnToTop);
Alpine.data('partnerPageController', () => PartnerPage);
Alpine.data('siteSwitcherController', () => SiteSwitcher);


Alpine.start();

PageTransition();
Splash();

document.addEventListener('DOMContentLoaded', () => {
  window.lazyloadInstance.update();
});
