import barbaRouter from '@barba/core';

export default function() {
	return {
		init() {
			const referrer = document.referrer;
      const currentDomain = window.location.hostname;
			const link = this.$refs.link;

			link.addEventListener('click', (e) =>{

					e.preventDefault();
					if (referrer && new URL(referrer).hostname !== currentDomain || window.history.state.states.length === 1) {
						barbaRouter.go(link.dataset.href);
					} else {
						history.back();
					}
					return;					

			});
		}
	};
}