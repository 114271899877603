import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules';

export default function() {
	return {
		init() {
			// console.log('ProjectDetail init()');
			const slideshow = document.getElementById('project-slideshow');

			if (!slideshow) {
				return;
			}

			Swiper.use([Pagination, Mousewheel]);
			new Swiper(slideshow, {
				direction: "vertical",
				speed: 500,
				slidesPerView: 1,
				spaceBetween: 30,
				mousewheel: true,
			});
		}
	};
}