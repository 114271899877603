export default function() {
	return {
		currentId: 0,
		init() {
			const menu = this.$refs.menu;
			const header = this.$refs.tableHeader;
			header.style.top = `${menu.clientHeight+60}px`;

			this.list = this.$refs.list.querySelectorAll('li');
		},
		filter(id) {
			this.currentId = id;
			this.list.forEach(element => {
				element.classList.remove('hidden');	
			});
			if(id === 0) {
				return;
			}
			this.list.forEach(element => {
				const ids = element.getAttribute('data-id').split('|');
				if(!ids.includes(id.toString())) {
					element.classList.add('hidden');
				}
			});
		}
	};
}