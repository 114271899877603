
import { print } from 'graphql';
import gql from 'graphql-tag';

export default function() {
	return {
		pageSize: 9,
		currentPage: 0,
		currentSiteId: undefined,
		featuredId: undefined,
		articles: [],
		entryCount: undefined,
		loading: false,
		setup(id) {
			this.currentSiteId = id;
			this._init();
		},
		_init() {
			this.featuredId = this.$refs.newsHome.dataset.featuredId;

			document.onscroll = () => {
				// console.log(Math.round(document.scrollingElement.scrollTop + document.body.clientHeight), document.body.scrollHeight);
				if (Math.round(document.scrollingElement.scrollTop + document.body.clientHeight + 150) >= document.body.scrollHeight) {
					// console.log(Math.round(document.scrollingElement.scrollTop + document.body.clientHeight + 100), document.body.scrollHeight);
					this.loadMore();
				}
			};

			this.loadMore();
		},

		loadMore() {
			if(this.loading) {
				return;
			};

			if(this.articles.length >= this.entryCount) {
				const topBtn = document.querySelector('.news-return-to-top');
				topBtn.classList.remove('opacity-0');

				return;
			};
			

			// console.log('load more');

			this.loading = true;
			const offset = this.pageSize * this.currentPage;
			this.currentPage++;
			
			fetch('/admin/actions/graphql/api', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify({
					query: print(gql`
					{
						entries(section: "news", limit: ${this.pageSize}, offset: ${offset}, siteId: ${this.currentSiteId}, id: ["not", ${this.featuredId}]) {
							id
							... on news_Entry {
								url
								title
								excerpt
								postDate @formatDateTime(format: "${window.app.dateFormat}", locale: "${window.app.language}")
								image {
									url @transform(handle: "newsThumbnail")
								}
							}
						}
						entryCount(section: "news", siteId: ${this.currentSiteId}, id: ["not", ${this.featuredId}])
					}`)
				})
			})
				.then(res => res.json())
				.then(data => {
					this.articles = [...this.articles, ...data.data.entries];
					this.$nextTick(() => window.lazyloadInstance.update());
					this.entryCount = data.data.entryCount;
					this.loading = false;
					// console.log('load more', this.articles);
				})
				.catch(err => {
					this.loading = false;
					console.log(err);
				});
			
		},	

	};
}