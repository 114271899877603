import barba from '@barba/core';

function checkSameContent(data) {
	let currentfadeContent = null;
	let nextfadeContent = null;
	if(data.current.container) {
		currentfadeContent = data.current.container.querySelectorAll('.barba-fade-content');
	}
	if(data.next.container) {
		nextfadeContent = data.next.container.querySelectorAll('.barba-fade-content');
	}
	if(currentfadeContent.length > 0 ) {
		currentfadeContent.forEach((content) => {
			const currentId = content.dataset.contentId;
			const nextId = Array.from(nextfadeContent).filter((nextContent) => nextContent.dataset.contentId == currentId);
			if(nextId.length > 0) {
				content.classList.add('transition-none', 'opacity-100');
				nextId.forEach((nextContent) => {	
					nextContent.classList.add('transition-none', 'opacity-100');
				});
			} else {
				content.classList.remove('transition-none', 'opacity-100');
				nextfadeContent.forEach((nextContent) => {	
					nextContent.classList.remove('transition-none', 'opacity-100');
				});
			}
		});
	}
}

export default function() {
	return {
		menuActive: false,

		get isMenuOpen() { return window.Alpine.store('menu').menuActive; },
		
		init() {
			document.addEventListener('click', (e) => {
				if (window.Alpine.store('menu').menuActive && e.target.id == 'overlay-menu-bg') {
					document.body.classList.remove('overlay-is-active');
					window.Alpine.store('menu').menuActive = false;
				}
			});

			const barbaContent = document.querySelectorAll('.barba-fade-content');
			barbaContent.forEach((content) => {
				if(barba.history.size < 2) {
					content.classList.add('enter');
				}
			});
		
			barba.hooks.after(() => {
				const barbaContent = document.querySelectorAll('.barba-fade-content');
				if(barbaContent.length > 0) {
					barbaContent.forEach((content) => {
						content.classList.add('enter');
					});
				}
			});
			barba.hooks.before((data) => {
				const barbaContent = document.querySelectorAll('.barba-fade-content');
				if(barbaContent.length > 0) {
					barbaContent.forEach((content) => {
						content.classList.remove('enter');
					});
				}
				checkSameContent(data);
			});
		},

		menuToggle() {
			window.Alpine.store('menu').menuActive = !window.Alpine.store('menu').menuActive;

			if (window.Alpine.store('menu').menuActive) {
				document.body.classList.add('overlay-is-active');
			} else {
				document.body.classList.remove('overlay-is-active');
			}
		}
	};
}