export default function() {
	return {
		current: 0,
		setup(id) {
			this.current = id;
		},
		setActive(id) {
			const current = document.querySelector('.top-menu .menu-link.active');
			if (current) {
				current.classList.remove('active');
			}

			this.$dispatch('topmenu', id);	

			this.current = id;
		}
	};
}