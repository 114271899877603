export default function (){
	return {
		currentSection: 0,
		currentCategory: 0,
		init() {
			if(!this.$refs.list) {
				return;
			}
			this.list = this.$refs.list.querySelectorAll('.filterable-item');
			this.listElement = document.getElementById('filterable-list');

			

		},
		setup(section, category) {
			this.currentSection = section;
			this.currentCategory = category;

			// get data from query hashtag
			const url = new URL(window.location.href);
			const filter = url.searchParams.get('f');
			if(filter) {
				this.filter(filter);
			}
		},
		filter(id) {
			// scroll into view 

			// if(this.listElement){
			// 	this.listElement.scrollIntoView({
			// 		behavior: 'smooth'
			// 	});				
			// }

			

			this.list.forEach(element => {
				element.classList.remove('hidden');
			});

			this.currentCategory = id;

			if(this.currentSection != 0 && this.currentCategory != 0) {
				this.list.forEach(element => {
					const ids = element.dataset.filters.split('|');
					if(!ids.includes(id+'')) {
						element.classList.add('hidden');
					}
				});
			}

			document.scrollingElement.scrollTop = 0;

			this.returnToTopVisibility();
		},
		onMenuChanged(e) {
			this.currentSection = e.detail;
			this.filter(0);
		},
		resetActive() {
			if(this.activeBnt) {
				this.activeBnt.querySelector('.sort-arrow').classList.add('hidden');
			}

		},
 		sort(event,id) {
			// console.log('sort', id, event.currentTarget);
			this.resetActive();
			const btn = event.currentTarget;
			this.activeBnt = btn;
			const icon = btn.querySelector('.sort-arrow');
			icon.classList.remove('hidden');


			if(this.currentSort == id && this.sortDirection == 'asc') {
				this.sortDirection = 'desc';
			} else {
				this.currentSort = id;
				this.sortDirection = 'asc';
			}

			if(this.sortDirection == 'asc') {
				icon.classList.add('rotate-180');
			} else {
				icon.classList.remove('rotate-180');
			}

			// sort items by title 
			const sorted = [...this.list].sort((a, b) => {
				const itemA = a.querySelector('.sortable-item[data-sort-id='+id+']');
				const itemB = b.querySelector('.sortable-item[data-sort-id='+id+']');

				const aTitle = itemA ? itemA.dataset.sortValue : null;
				const bTitle = itemB ? itemB.dataset.sortValue : null;
				if (aTitle < bTitle) {
					return this.sortDirection == 'asc' ? -1 : 1;
				}
				if (aTitle > bTitle) {
					return this.sortDirection == 'asc' ? 1 : -1;
				}
				return 0;
			}).map(item => item.outerHTML);

			// replace items with sorted items
			this.$refs.list.innerHTML = sorted.join('');
			this.list = this.$refs.list.querySelectorAll('.filterable-item'); 
		
			document.scrollingElement.scrollTop = 0;
			
			
		
		},
		returnToTopVisibility() {
			const listContainer = document.querySelector('.list-container');
			console.log(this.windowHasScroll(listContainer), this.$refs.button);

			if(listContainer) {
				const returnToTop = document.querySelector('.return-to-top');
				if(!this.windowHasScroll(listContainer)) {
					returnToTop.classList.add('hidden');
				} else {
					returnToTop.classList.remove('hidden');
				}
			}
		},
		windowHasScroll(listContainer) {
			return listContainer.clientHeight > window.innerHeight;
		}
	};
}