
import barbaRouter from '@barba/core';
export default function() {

	
	return {
		current: 0,
		activeCategory: 0,
		parentId: 0,

		setup(catId, activeCatId, parentId, current) {
			if(activeCatId){
				this.activeCategory = activeCatId;
			}

			if(catId){
				this.current = catId;
			}

			if(parentId){
				this.parentId = parentId;
			}

			if(current){
				this.current = current;
			}
		},

		setCategory(catId, parentId, event = null, hasChildren = false) {
			// console.log('set category', catId, this.parentId, event, hasChildren, barbaRouter);
			// if(catId === this.parentId && hasChildren && this.activeCategory !== 0){
			// 	this.activeCategory = 0;
			// 	this.parentId = 0;
			// 	event.preventDefault();
			// 	return;
			// }

			this.activeCategory = catId;
			this.parentId = parentId;


			if(event && hasChildren){
				// console.log('prevent default', event, hasChildren);
				// const target = event.currentTarget;
				// const resetUrl = target.dataset.resetUrl;
				// target.attributes.href.value = resetUrl;
				event.preventDefault();
				return;
			}
		},
	};
}	