import anime from 'animejs/lib/anime.es.js';


export default function() {
	const splash = document.getElementById('splash');
	// console.log('Splash');

	if(!splash) return;
	
	let e = {};

	function  mapRange(inMin, inMax, outMin, outMax, value) {
		var inRange = inMax - inMin,
		outRange = outMax - outMin;
		return outMin + ((value - inMin) / inRange * outRange || 0);
	}

	e.splash = document.getElementById("splash");
	e.container = e.splash.querySelector("div.splash-container");
	e.inner = e.container.querySelector("div#splash-inner");
	e.canvas = e.splash.querySelector("canvas");
	e.ctx = e.canvas.getContext("2d");
	e.svgPath = "M467.808,65.701l26.667,-0c-1.541,11.793 -6.835,22.575 -14.682,31.19c5.536,-0.336 10.735,-0.577 14.97,-0.577l11.36,-0l-0,21.227l-72.779,-0c-33.212,-0 -57.664,-22.671 -60.889,-51.84l26.859,-0c2.984,16.269 16.269,28.784 34.367,28.784c18.098,-0 31.335,-12.419 34.127,-28.784Zm-146.948,-23.151l-0.048,-0l20.456,-16.558c6.498,9.482 10.156,20.986 10.156,33.404l0,0.337c0,32.827 -25.943,59.686 -61.61,59.686c-17.473,-0 -32.538,-6.402 -43.368,-16.751l19.83,-16.028c6.113,5.824 14.344,9.386 23.875,9.386c20.504,-0 34.704,-16.173 34.704,-35.956l0,-0.337c0,-6.161 -1.396,-12.033 -3.995,-17.183Zm-92.32,17.135c0,-32.827 25.944,-59.685 61.611,-59.685c17.52,-0 32.586,6.402 43.416,16.799l-19.831,16.028c-6.113,-5.824 -14.344,-9.434 -23.922,-9.434c-20.505,-0 -34.704,16.173 -34.704,35.955l0,0.337c0,6.258 1.444,12.13 4.043,17.28l-20.457,16.558c-6.498,-9.53 -10.156,-21.034 -10.156,-33.453l0,-0.385Zm143.723,-5.921c2.551,-29.987 27.436,-53.717 61.37,-53.717c33.934,-0 58.724,23.634 61.082,53.717l-26.714,-0c-2.263,-17.136 -15.885,-30.661 -34.657,-30.661c-18.772,-0 -32.297,13.381 -34.367,30.661l-26.714,-0Zm-266.708,-51.696l26.377,-0l24.789,64.451l-13.189,34.271l-37.977,-98.722Zm88.762,-0l26.329,-0l-44.331,115.376l-26.377,-0l44.379,-115.376Zm-157.11,115.377l0,-22.574l50.829,-0l0,22.574l-50.829,-0Zm0,-46.833l0,-22.575l42.55,-0l0,22.575l-42.55,-0Zm-37.207,46.833l0,-115.424l25.222,-0l0,115.424l-25.222,-0Zm37.207,-92.849l0,-22.575l50.011,-0l0,22.575l-50.011,-0Z";
	e.currentScale = 1;
	e.originalSvgWidth = 506.1;
	e.originalSvgHeight = 119.4;
	e.initialScale = !1;
	e.maxScale = !1;
	e.canvas.width = window.innerWidth;
	e.canvas.height = window.innerHeight;
	e.initialScale = .4625 * e.canvas.width / e.originalSvgWidth;
	e.maxScale = e.canvas.width / (.04 * e.originalSvgWidth);
	// console.log('Splash', e);

	anime({
		targets: "#splash .splash-overlay",
		scaleX: [1, 0],
		duration: 3000,
		delay: 250,
		easing: "easeOutExpo"
	});

	anime({
		targets: "#splash",
		opacity: 0,
		duration: .95,
		delay: 3000,
		update: function(anim) {
			const r = anim.progress / 100;
			const range = mapRange(0, 1, 1, e.maxScale, 0 === r ? 0 : Math.pow(2, 50 * (r - 1)));

			Math.abs(e.currentScale - range) > .01 && (e.currentScale = range,
			window.requestAnimationFrame(()=>createSplash(e)));

			if(r === 1) {
				document.body.classList.add('splash-complete');
			}

			// console.log('anim update', anim.progress/100, range, e.maxScale);
		},
	});

	function createSplash(e) {
		// console.log('createSplash');
		e.ctx.fillStyle = "white";
		e.ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
		let t = e.initialScale + (e.currentScale - 1) * (e.maxScale - e.initialScale) / (e.maxScale - 1);
		e.initialScale;
		e.maxScale;
		e.initialScale;
		let r = .53312 * e.originalSvgWidth
		  , o = .43 * e.originalSvgHeight
		  , i = window.innerWidth / 2 - r * t
		  , s = window.innerHeight / 2 - o * t;
		e.ctx.save();
		e.ctx.translate(i, s);
		e.ctx.scale(t, t);
		let n = new Path2D(e.svgPath);
		e.ctx.globalCompositeOperation = "xor";
		e.ctx.fill(n);
		e.ctx.restore();
	}

	function resizeCanvas() {
		e && (e.canvas.width = window.innerWidth,
		e.canvas.height = window.innerHeight,
		e.initialScale = .4625 * e.canvas.width / e.originalSvgWidth,
		e.maxScale = e.canvas.width / (.05996 * e.originalSvgWidth),
		createSplash(e));
	}

	createSplash(e);

	window.addEventListener("resize", function() {
		resizeCanvas();
	});
}