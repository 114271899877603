
export default function() {
	return {
		id: 'collapsables',
		setup(id) {
			this.id = id;
			this._init();
		},
		_init() {
			const container = document.getElementById(this.id);

			this.current = container.querySelector('.collapsable.open');
			this.default = container.querySelector('.collapsable--default');

			const collapsables = container.querySelectorAll('.collapsable');
			collapsables.forEach((collapsable) => {

				const button = collapsable.querySelector('.collapsable__trigger');
				const content = collapsable.querySelector('.collapsable__content');
				const contentInner = collapsable.querySelector('.collapsable__content__inner');

				collapsable.setAttribute('data-height', contentInner.clientHeight);
				// if(collapsable.classList.contains('open')) {
				// 	content.style.height = contentInner.clientHeight+'px';
				// } else {
				// 	content.style.height = 0;
				// }
				if(button) {
					button.addEventListener('click', () => {
						// console.log('Collapsable click');
						if(collapsable.classList.contains('open') && collapsable != this.default) {
							content.style.height = 0;
							collapsable.classList.remove('open');
							this.current = this.default;
							this.default.classList.add('open');
							this.default.querySelector('.collapsable__content').style.height = this.default.getAttribute('data-height')+'px';

							return;
						}

						if(this.current) {
							this.current.classList.remove('open');
							this.current.querySelector('.collapsable__content').style.height = 0;	
						}

						collapsable.classList.toggle('open');
						content.style.height = contentInner.clientHeight+'px';
						this.current = collapsable;

						document.scrollingElement.scrollTop = 0;
					});
				}
			});

			window.addEventListener('resize', () => {
				collapsables.forEach((collapsable) => {
					const content = collapsable.querySelector('.collapsable__content');
					const contentInner = collapsable.querySelector('.collapsable__content__inner');
					collapsable.setAttribute('data-height', contentInner.clientHeight);
					if(collapsable.classList.contains('open')) {
						content.style.height = 'auto';
						// console.log('resize', contentInner.clientHeight);

						setTimeout(() => {
							// console.log('resize timeout', contentInner.clientHeight);
							content.style.height = contentInner.clientHeight+'px';;
						}, 100);
					} else {
						content.style.height = 0;
					}
				});
			});

			// trigger resize event to set initial height
			window.dispatchEvent(new Event('resize'));
		},
	};
}