export default class SlideProgress {

	constructor(slideshow, swiper) {
		this.swiper = swiper;
		this.total = swiper.slides.length;
		this.progressFill = slideshow.querySelector('.slideshow-pagination-progress-bar-fill');
		this.progressCurrent = slideshow.querySelector('.slideshow-pagination-current');
		this.progressTotal = slideshow.querySelector('.slideshow-pagination-total');
		this.init();
	}

	init() {
		this.total = this.swiper.slides.length;
		if(this.progressTotal) {
			this.progressTotal.innerHTML = this.total;
			this.updateProgress();			
		}

	}

	updateProgress() {
		let progress = Math.round(100 / this.total * (this.swiper.realIndex + 1));
		this.progressFill.style.height = progress + '%';
		this.progressCurrent.innerHTML = this.swiper.realIndex + 1;
	}
}